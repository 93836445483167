<template>
  <section class="page-land">
    <div class="banner">
      <p class="hd">JWP LAND</p>
      <p class="title">
        <span>Global Land Reserve</span>
      </p>
      <p class="desc">
        <span
          >A powerful guarantee for the sustainable development of
          corporation</span
        >
      </p>
    </div>
    <ul class="lands">
      <li class="land usa">
        <div class="hd">
          <van-image
            width="39"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/land/usa-flag.png"
          />
          <div class="addr">
            <p class="en">42 UNIT APARTMENT</p>
            <p class="cn">Los Angeles, CA</p>
          </div>
        </div>
        <div class="map" id="mapOne"></div>
        <div class="imgs">
          <van-image
            fit="cover"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/land/usa-map.jpg"
          />
          <van-image
            fit="cover"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/land/usa-1.jpg"
          />
          <van-image
            fit="cover"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/land/usa-2.jpg"
          />
        </div>
        <div class="areas">
          <p class="area">
            <span class="k">Plot area：</span>
            <span class="v">1164.4 m²</span>
          </p>
          <p class="area">
            <span class="k">Gross floor area：</span>
            <span class="v">5754 m²</span>
          </p>
        </div>
      </li>
      <li class="land th">
        <div class="hd">
          <van-image
            width="43"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/land/th-flag.png"
          />
          <div class="addr">
            <p class="en">SEAVIEW LAND AO PO,PHUKET</p>
            <p class="cn">East coast of Phuket, Thailand</p>
          </div>
        </div>
        <div class="map" id="mapTwo"></div>
        <div class="imgs">
          <van-image
            fit="cover"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/land/th-map.jpg"
          />
          <van-image
            fit="cover"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/land/th-1.jpg"
          />
          <van-image
            fit="cover"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/land/th-2.jpg"
          />
        </div>
        <div class="areas">
          <p class="area">
            <span class="k">Plot area：</span>
            <span class="v">97600 m²</span>
          </p>
          <p class="area">
            <span class="k">Gross floor area：</span>
            <span class="v">72500 m²</span>
          </p>
        </div>
      </li>
      <li class="land mo">
        <div class="hd">
          <van-image
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/land/mo-flag.png"
          />
          <div class="addr">
            <p class="en">
              Five-star Apartment Hotel in Korahin, northern Montenegro
            </p>
            <p class="cn">Korahin City, northern Montenegro</p>
          </div>
        </div>
        <div class="map" id="mapThree"></div>
        <div class="imgs">
          <van-image
            fit="cover"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/land/mo-map.jpg"
          />
          <van-image
            fit="cover"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/land/mo-1.jpg"
          />
          <van-image
            fit="cover"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/land/mo-2.jpg"
          />
        </div>
        <div class="areas">
          <p class="area">
            <span class="k">Plot area：</span>
            <span class="v">7559 m²</span>
          </p>
          <p class="area">
            <span class="k">Gross floor area：</span>
            <span class="v">12094 m²</span>
          </p>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  beforeCreate() {
    document.title = "Land Reserve";
  },
  created() {
    this.$utils
      .loadSrc(
        "https://webapi.amap.com/maps?v=1.4.15&key=f6ab1e87de0959bf1c3a01a34ff6f0cc",
        "AMAP_SCRIPT",
        "js"
      )
      .then(() => {
        window.setTimeout(() => {
          this.initMap();
        }, 100);
      });
  },
  methods: {
    initMap() {
      if (!window.AMap) {
        return false;
      }
      // http://www.thingjs.com/guide/cn/ThingJS_Summary/CityBuilder_Data.htm

      // 创建 AMap.Icon 实例
      const icon = new window.AMap.Icon({
        size: new window.AMap.Size(32, 32), // 图标尺寸
        image: "//static.hoolihome.com/global-jwp/assets/img/location.svg", // Icon的图像
        imageSize: new window.AMap.Size(32, 32) // 根据所设置的大小拉伸或压缩图片
      });

      const layerOne = new window.AMap.TileLayer({
        getTileUrl:
          "http://mt{1,2,3,0}.google.cn/vt/lyrs=y&hl=en&gl=en&x=[x]&y=[y]&z=[z]&s=Galile"
      });
      const mapOne = new window.AMap.Map("mapOne", {
        zoom: 11,
        center: [-118.15, 34.04],
        resizeEnable: true,
        // scrollWheel: false,
        layers: [layerOne]
      });
      const markerOne = new window.AMap.Marker({
        position: new window.AMap.LngLat(-118.15, 34.04),
        offset: new window.AMap.Pixel(-10, -16),
        icon, // 添加 Icon 图标 URL
        title: "Los Angeles, CA"
      });
      mapOne.add(markerOne);

      const layerTwo = new window.AMap.TileLayer({
        getTileUrl:
          "http://mt{1,2,3,0}.google.cn/vt/lyrs=y&hl=en&gl=en&x=[x]&y=[y]&z=[z]&s=Galile"
      });
      const mapTwo = new window.AMap.Map("mapTwo", {
        zoom: 11,
        center: [98.43, 8.04],
        resizeEnable: true,
        // scrollWheel: false,
        layers: [layerTwo]
      });
      const markerTwo = new window.AMap.Marker({
        position: new window.AMap.LngLat(98.43, 8.04),
        offset: new window.AMap.Pixel(-10, -16),
        icon, // 添加 Icon 图标 URL
        title: "East coast of Phuket, Thailand"
      });
      mapTwo.add(markerTwo);

      const layerThree = new window.AMap.TileLayer({
        getTileUrl:
          "http://mt{1,2,3,0}.google.cn/vt/lyrs=y&hl=en&gl=en&x=[x]&y=[y]&z=[z]&s=Galile"
      });
      const mapThree = new window.AMap.Map("mapThree", {
        zoom: 11,
        center: [19.53, 42.83],
        resizeEnable: true,
        // scrollWheel: false,
        layers: [layerThree]
      });
      const markerThree = new window.AMap.Marker({
        position: new window.AMap.LngLat(19.53, 42.83),
        offset: new window.AMap.Pixel(-10, -16),
        icon, // 添加 Icon 图标 URL
        title: "Korahin City, northern Montenegro"
      });
      mapThree.add(markerThree);
    }
  }
};
</script>

<style lang="less" scoped>
.page-land {
  background-color: #f6f6f6;
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 360px;
    background: url("//static.hoolihome.com/global-jwp/assets/m/landmark/land/banner.jpg")
      no-repeat center center / cover;
    .hd {
      padding-bottom: 83px;
      font-size: 22px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 30px;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    }
    .title {
      padding-bottom: 16px;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        border-bottom: 2px rgba(255, 255, 255, 0.5) solid;
      }
    }
    .desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 22px 57px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      span {
        display: inline-block;
        line-height: 17px;
      }
    }
  }
  .lands {
    padding-bottom: 50px;
    .land {
      margin-top: 16px;
      padding: 30px 22px;
      background-color: #fff;
      &.mo {
        .hd {
          .van-image {
            width: 68px;
          }
        }
      }
      .hd {
        display: flex;
        align-items: center;
        padding-bottom: 18px;
        .addr {
          margin-left: 19px;
          .en {
            font-size: 15px;
            font-weight: bold;
            color: rgba(38, 38, 38, 1);
            line-height: 21px;
          }
          .cn {
            margin-top: 2px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(38, 38, 38, 1);
            line-height: 17px;
          }
        }
      }
      .map {
        width: 331px;
        height: 170px;
        border-radius: 4px;
        /deep/ .amap-logo {
          display: none !important;
        }
        /deep/ .amap-copyright {
          display: none !important;
        }
      }
      .imgs {
        display: flex;
        justify-content: space-between;
        padding: 16px 0;
        .van-image {
          width: 104px;
          height: 98px;
        }
      }
      .areas {
        display: flex;
        justify-content: space-between;
        .area {
          font-size: 12px;
          color: rgba(38, 38, 38, 1);
          line-height: 17px;
          .k {
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
